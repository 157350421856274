import { useState, useEffect, forwardRef } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import "./RenewBlock.css";

export default function RenewBlock(props) {
    const navigate = useNavigate();

    const calculateDaysDifference = (createdAt) => {
        const currentDate = new Date();
        const vencimentoDate = new Date(createdAt);
        vencimentoDate.setFullYear(vencimentoDate.getFullYear() + 1);

        // Calcula a diferença em milissegundos e converte para dias
        const timeDiff = vencimentoDate - currentDate;
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        return daysDiff;
    };

    const isLessThanTwoMonths = (createdAt) => {
        const daysDiff = calculateDaysDifference(createdAt);
        return daysDiff <= 65; // Aproximadamente 2 meses
    };

    const calculateVencimentoAbreviado = (createdAt) => {
        const vencimentoDate = new Date(createdAt);
        vencimentoDate.setFullYear(vencimentoDate.getFullYear() + 1);

        return vencimentoDate.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit", // Mês abreviado (números)
            year: "numeric",
        });
    };

    const calculateRenewalValue = (createdAt) => {
        const creationDate = new Date(createdAt);

        // Datas de corte
        const date1 = new Date("2023-12-25");
        const date2 = new Date("2024-07-21");
        const date3 = new Date("2024-10-01");

        // Verifica em qual faixa de data o cadastro se encaixa
        if (creationDate >= date3) {
            return "R$ 1.999,90";
        } else if (creationDate >= date2) {
            return "R$ 1.599,90";
        } else if (creationDate >= date1) {
            return "R$ 1.199,90";
        } else {
            return "R$ 1.199,90";
        }
    };

    useEffect(() => {
        if (!isLessThanTwoMonths(props.user.lastPaid || props.user.createdAt)) {
            navigate("/");
        }
    }, [props.user]);

    return (
        <>
            <div className="admin-login-page">
                <div className="entrance-payment">
                    <div className="block-warning">
                        Sua conta foi bloqueada por falta de pagamento da
                        franquia, a qual terminou em{" "}
                        <strong>
                            {calculateVencimentoAbreviado(
                                props.user.lastPaid || props.user.createdAt
                            )}
                        </strong>
                        .
                    </div>
                    <div className="payment-warning">
                        Realize o pagamento de{" "}
                        <strong>
                            {calculateRenewalValue(props.user.createdAt)}
                        </strong>{" "}
                        para liberar seu acesso.
                    </div>
                    <div className="ban-warning">
                        Em{" "}
                        <strong>
                            {calculateDaysDifference(
                                props.user.lastPaid || props.user.createdAt
                            ) + 17}{" "}
                            dias
                        </strong>{" "}
                        sua conta será{" "}
                        <strong>permanentemente desativada</strong>.
                    </div>
                    <a
                        href={`${window.location.protocol}//${
                            window.location.hostname == "localhost"
                                ? `${window.location.hostname}:5000`
                                : window.location.hostname
                        }/api/mercadopago/renewlink`}
                        target="_blank"
                        className="entrance-payment-btn"
                    >
                        Realizar pagamento
                    </a>
                    <a href="/logout" className="entrance-payment-leave">
                        Sair
                    </a>
                </div>
            </div>
        </>
    );
}
