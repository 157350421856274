import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader/Loader.js";
import CopyDiv from "../../../components/CopyDiv/CopyDiv.js";

import "./Payments.css";

import { useContext } from "react";
import { UserContext } from "../../../App.js";

export default function Payments() {
    const { adminUsers, adminGroups, adminProducts } = useContext(UserContext);

    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const fetchUsers = async () => {
        try {
            const response = await fetch("/api/users/getpayments", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <div className="admin-users-container">
            <div className="admin-users-list">
                {users ? (
                    <>
                        <div className="admin-user admin-payment-top">
                            <div className="admin-user-email admin-payment-selected">
                                Selecionados: {selectedUsers.length}
                            </div>
                            <div className="admin-user-signed total-value">
                                Total selecionados
                                <span className="admin-signed-date">
                                    {selectedUsers
                                        .map((s) =>
                                            users.find((u) => u.id === s)
                                        )
                                        .reduce(
                                            (prev, curr) => prev + curr.amount,
                                            0
                                        )
                                        .toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                </span>
                            </div>
                            <div className="admin-user-signed ">
                                Repasse selecionados
                                <span className="admin-signed-date">
                                    {Math.round(
                                        (selectedUsers
                                            .map((s) =>
                                                users.find((u) => u.id === s)
                                            )
                                            .reduce(
                                                (prev, curr) =>
                                                    prev + curr.amount,
                                                0
                                            ) *
                                            0.3333) /
                                            2
                                    ).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}
                                </span>
                            </div>
                        </div>
                        {users?.map((user) => (
                            <div
                                className="admin-user admin-payment"
                                key={user.date}
                            >
                                <span
                                    className="admin-order-check"
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        const newSelUsers = [...selectedUsers];
                                        if (selectedUsers.includes(user.id))
                                            newSelUsers.splice(
                                                newSelUsers.indexOf(user.id),
                                                1
                                            );
                                        else newSelUsers.push(user.id);
                                        setSelectedUsers(newSelUsers);
                                    }}
                                >
                                    <div
                                        className={`admin-order-checked ${
                                            selectedUsers.includes(user.id)
                                                ? "checked"
                                                : ""
                                        }`}
                                    ></div>
                                </span>
                                <span
                                    className={`admin-user-new ${
                                        new Date(user.date).getTime() >
                                        Date.now() - 1000 * 60 * 60 * 24 * 7
                                            ? "shown"
                                            : ""
                                    }`}
                                >
                                    Novo!
                                </span>

                                {user.reference.startsWith("CAD") ? (
                                    <div className="admin-user-type cad">
                                        Cadastro
                                    </div>
                                ) : (
                                    <div className="admin-user-type ren">
                                        Renovação
                                    </div>
                                )}

                                <div className="admin-user-name">
                                    {
                                        adminUsers?.find(
                                            (u) => u.id === user.userId
                                        )?.name
                                    }
                                </div>
                                <CopyDiv
                                    className="admin-user-email"
                                    value={
                                        adminUsers?.find(
                                            (u) => u.id === user.userId
                                        )?.email
                                    }
                                >
                                    {
                                        adminUsers?.find(
                                            (u) => u.id === user.userId
                                        )?.email
                                    }
                                </CopyDiv>

                                <div className="admin-user-signed total-value">
                                    Valor total
                                    <span className="admin-signed-date">
                                        {user.amount.toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </span>
                                </div>

                                <div className="admin-user-signed">
                                    Repasse
                                    <span className="admin-signed-date">
                                        R${" "}
                                        {Math.round((user.amount * 0.3333) / 2)}
                                    </span>
                                </div>

                                <div className="admin-user-signed">
                                    Data
                                    <span className="admin-signed-date">
                                        {new Date(
                                            user.date
                                        ).toLocaleDateString()}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="payments-loader">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
}
