import "./AIstem.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import useLongPress from "../../util/useLongPress";

import SpeechRecognition, {
    useSpeechRecognition,
} from "react-speech-recognition";
import Einstein from "../Einstein/Einstein";
import { TypeAnimation } from "react-type-animation";
import CopyDiv from "../CopyDiv/CopyDiv";

import AIstemChat from "../AIstemChat/AIstemChat.js";

import { UserContext } from "../../App.js";

import Cookies from "js-cookie";
import { notEmpty } from "../../util/utils.js";
import { getConfig } from "../../util/config.js";

let aistemTimeout;

// const audio = new Audio();

// const accessKey = "sucHvS0gxIFe7d0WfX8JEbF8KvqzlHLrqWetxtrvBOrmEzl7hzNs1Q==";

const VoiceWidget = (props) => {
    const {
        user,
        setUser,
        orders,
        integrations,
        messages,
        setMessages,
        hidden,
        setHidden,
    } = useContext(UserContext);

    const messagesRef = useRef([]);

    useEffect(() => {
        messagesRef.current = messages;
        // console.log(messages);
    }, [messages]);

    const [audio] = useState(new Audio());
    const [beep1] = useState(new Audio("/AIstem/aistemsound1.mp3"));
    const [beep2] = useState(new Audio("/AIstem/aistemsound2.mp3"));
    const [beep3] = useState(new Audio("/AIstem/aistemsound3.mp3"));

    useEffect(() => {
        beep1.volume = getConfig(user, "aistemVolume") / 100;
        beep2.volume = getConfig(user, "aistemVolume") / 100;
        beep3.volume = getConfig(user, "aistemVolume") / 100;
    }, [user]);

    const [reply, setReply] = useState(null);
    const [lastMessage, setLastMessage] = useState(null);
    const [waiting, setWaiting] = useState(false);

    // const [keywordDetections, setKeywordDetections] = useState([]);
    // const { keywordDetection, isLoaded, isListening, error, init, start, stop, release } = usePorcupine();

    const [images, setImages] = useState(null);
    const [links, setLinks] = useState(null);
    const [content, setContent] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const [logoStage, setLogoStage] = useState(0);
    // const [refresh, setRefresh] = useState(Date.now());

    const [speaking, setSpeaking] = useState(false);
    const [speakingAudio, setSpeakingAudio] = useState(false);
    const [aistemOpen, setAistemOpen] = useState(false);

    const [mute, setMute] = useState(Cookies.get("mute") === "true");

    useEffect(() => {
        Cookies.set("mute", mute, {
            path: "/",
            expires: 10 * 365 * 24 * 60 * 60,
        });
    }, [mute]);

    const commands = [
        {
            command: "Einstein",
            callback: (comm, spokenPhrase, test) => {
                aistemCommand(spokenPhrase);
            },
            isFuzzyMatch: true,
            fuzzyMatchingThreshold: 0.1,
            // matchInterim: true,
            // bestMatchOnly: true,
        },
        {
            command: ["para", "cala a boca", "cale a boca", "silêncio"],
            callback: ({ resetTranscript }) => {
                resetTranscript();
                audio.pause();
                audio.currentTime = 0;
                setSpeaking(false);
                setSpeakingAudio(false);
            },
        },
    ];

    const {
        transcript,
        browserSupportsSpeechRecognition,
        resetTranscript,
        browserSupportsContinuousListening,
    } = useSpeechRecognition({ commands });

    useEffect(() => {
        // alert(browserSupportsContinuousListening);
        if (browserSupportsContinuousListening && !mute)
            SpeechRecognition.startListening({
                language: "pt-BR",
                continuous: true,
            });
    }, []);

    const [listening, setListening] = useState(false);
    const [listeningManual, setListeningManual] = useState(false);

    useEffect(() => {
        if (
            transcript?.toLowerCase().includes("einstein") &&
            !waiting &&
            !listeningManual &&
            !mute
        ) {
            audio.pause();
            audio.currentTime = 0;
            setSpeaking(false);
            setSpeakingAudio(false);
            if (!listening) beep2.play().catch((e) => {});
            setListening(true);
            clearTimeout(aistemTimeout);
            aistemTimeout = setTimeout(() => setListening(false), 4001);
        } else {
            setListening(false);
            if (listening) resetTranscript();
        }
    }, [transcript, listening]);

    const aistemCommand = (spokenPhrase, ignoreEinstein) => {
        if (waiting || mute) return resetTranscript();
        clearTimeout(aistemTimeout);
        resetTranscript();
        // alert(spokenPhrase);
        if (
            transcript?.toLowerCase().includes("einstein") ||
            ignoreEinstein ||
            listeningManual
        ) {
            let text = spokenPhrase.replace(/^einstein\s?/i, "");

            if (!text && !listeningManual) {
                // beep2.play();
                return setListeningManual(true);
            }

            beep1.play().catch((e) => {});

            if (
                [
                    "nada",
                    "nada não",
                    "pare",
                    "parar",
                    "chega",
                    "cale a boca",
                    "cala a boca",
                    "cala boca",
                ].includes(text.toLowerCase())
            ) {
                return;
            }

            text = text.replace(/Starbucks/gi, "Starbox");
            text = text.replace(/Horizonte/gi, "Orizom");
            text = text.replace(/Horizon/gi, "Orizom");
            text = text.replace(/Einstein/gi, "AI-stem");

            setMessages([
                {
                    id: Date.now(),
                    createdAt: Date.now(),
                    type: "user",
                    content: text,
                },
                ...messagesRef.current,
            ]);

            // props.addMessage({
            //     type: "user",
            //     content: text,
            // });
            setReply(text);
        }
    };

    //     {
    //         command: "Einstein *",
    //         callback: (food) => alert(food),
    //         isFuzzyMatch: true,
    //         fuzzyMatchingThreshold: 0.2,
    //     },

    useEffect(() => {
        if (listeningManual) {
            clearTimeout(aistemTimeout);
            aistemTimeout = setTimeout(() => {
                setListeningManual(false);
                setListening(false);
                beep3.play().catch((e) => {});
                if (transcript) {
                    resetTranscript();
                    aistemCommand(transcript, true);
                }
            }, 3000);
        }

        // SpeechRecognition.startListening({ language: "pt-BR", continuous: true });
        // document.addEventListener("keydown", escFunction, false);
        // return () => document.removeEventListener("keydown", escFunction, false);
    }, [listeningManual, transcript]);

    const onMicLongPress = async () => {
        // alert("mudo");
        setMute(!mute);
        if (mute) {
            if (browserSupportsContinuousListening)
                SpeechRecognition.startListening({
                    language: "pt-BR",
                    continuous: true,
                });
        } else {
            SpeechRecognition.stopListening();
            setListening(false);
            setListeningManual(false);
        }
    };

    const onMicClick = async () => {
        if (mute) {
            setMute(false);
        }
        if (!browserSupportsSpeechRecognition)
            return alert(
                "Seu navegador não é compatível com o reconhecimento de voz! Sugerimos o uso do Google Chrome, Edge e Safari. \nAinda é possível conversar normalmente pelo chat."
            );

        resetTranscript();

        if (!browserSupportsContinuousListening)
            SpeechRecognition.startListening({ language: "pt-BR" });

        // SpeechRecognition.startListening({ language: "pt-BR" });
        beep2.play().catch((e) => {});
        setListeningManual(true);
        setLastMessage(null);
        audio.pause();
        audio.currentTime = 0;
        setSpeaking(false);
    };

    const minLongPressEvent = useLongPress(onMicLongPress, onMicClick, {
        delay: 500,
    });

    // useEffect(() => {
    //     if (transcript && !listening) {
    //         resetTranscript();

    //         if (
    //             ["nada", "nada não", "pare", "parar", "chega", "cale a boca", "cala a boca", "cala boca"].includes(
    //                 transcript.toLowerCase()
    //             )
    //         ) {
    //             return;
    //         }

    //         let text = transcript;

    //         text = text.replace(/Starbucks/gi, "Starbox");
    //         text = text.replace(/Horizon/gi, "Orizom");
    //         text = text.replace(/Einstein/gi, "AI-stem");

    //         setMessages([
    //             {
    //                 id: Date.now(),
    //                 createdAt: Date.now(),
    //                 type: "user",
    //                 content: text,
    //             },
    //             ...messagesRef.current,
    //         ]);

    //         // props.addMessage({
    //         //     type: "user",
    //         //     content: text,
    //         // });
    //         setReply(text);
    //     }
    // }, [transcript, listening]);

    useEffect(() => {
        setImages(null);
        setLinks(null);
        setContent(null);

        let textContent = lastMessage?.response || lastMessage?.content;

        if (textContent) {
            let messageLinks = [
                ...textContent?.matchAll(
                    /(?=[^!]\[(!\[.+?\]\(.+?\)|.+?)]\(([^\)]+)\))/gi
                ),
            ];
            let messageImages = [
                ...textContent?.matchAll(
                    /(?=!\[(!\[.+?\]\(.+?\)|.+?)]\(([^\)]+)\))/gi
                ),
            ];

            if (messageLinks?.length)
                setLinks(
                    messageLinks.map((e) => {
                        return {
                            text: e[1],
                            url: e[2],
                        };
                    })
                );

            if (messageImages?.length)
                setImages(
                    messageImages.map((e) => {
                        return {
                            text: e[1],
                            url: e[2],
                        };
                    })
                );

            let cont = "";
            cont = textContent?.replace(/!?\[([^\[\]]*)\]\((.*?)\)\.?/gm, "");
            // content = content.replace(/\d+\s*[-\\.)]?\s+/gi, "");
            cont = cont.replace(/ *\【[^】]*\】\.? */g, "");
            cont = cont.replace(/\n\n+/g, "\n");
            cont = cont.replace(/\**/g, "");
            // content = props.message.content;
            setContent(cont);
        }
    }, [lastMessage]);

    useEffect(() => {
        if (speakingAudio) {
            setLogoStage(-1);
            setSpeaking(true);
            setTimeout(() => {
                setLogoStage(0);
                setTimeout(() => {
                    setLogoStage(1);
                }, 1800);
            }, 600);
        } else {
            setTimeout(() => {
                setSpeaking(false);
            }, 2000);
        }

        if (!speakingAudio && logoStage === 1) {
            setTimeout(() => {
                setLogoStage(2);
            }, 200);
        }
    }, [speakingAudio]);

    const radius = 500;

    const getCirclePos = (angle) => {
        let x = radius * Math.sin((Math.PI * 2 * angle) / 360);
        let y = radius * Math.cos((Math.PI * 2 * angle) / 360);
        return { x: x, y: y };
    };

    const numOrders = Object.values(orders).filter(
        (order) =>
            order.status === 0 || order.status === 1 || order.status === 5
    ).length;

    const stages = [
        {
            name: "Endereço",
            description: `Vamos iniciar nosso Plano de voo começando pelo endereço de envio!
Em todos marketplaces que vender, é necessário inserir o nosso endereço, para que em caso de devolução, o pacote retorne para nós!
Caso utilize outro endereço, existirá o risco do coletor não aceitar o pacote, ou de serem gerados custos de devolução sobre os quais a Orizom não se responsabiliza!

Nome: ${user.name}
Endereço: Rua Manoel Vilaboim, 79
Bairro: Prof. Jardim Francisco Morato
CEP: 07910-130
Cidade: Francisco Morato - SP`,
            button: "Já inseri o endereço!",
            bonus: 10,
        },
        {
            name: "Impressora",
            description: `Imprimimos suas etiquetas com impressoras térmicas!
Desta forma é necessário selecionar a impressora "térmica" em todos os marketplaces!
Caso não faça essa alteração, não conseguirei puxar automaticamente seus pedidos e nem você conseguirá subir o pedido manualmente!
Segue alguns tutoriais para te auxiliar. Caso venda em outros marketplaces, basta procurar o campo de configuração de impressora e fazer a mudança!`,
            links: [
                {
                    name: "Tutorial Mercado Livre",
                    url: "https://www.youtube.com/watch?v=5oQOh2Q6v8A",
                },
                {
                    name: "Tutorial Shopee",
                    url: "https://www.youtube.com/watch?v=bgaeUTwKsaQ",
                },
                {
                    name: "Tutorial Magalu",
                    url: "https://www.youtube.com/watch?v=Cnv7EK1i6us",
                },
            ],
            button: "Já configurei",
            bonus: 10,
        },
        {
            name: "Integração",
            description: `Em sua conta do Bling, é necessário fazer as configurações dos vídeos a seguir! Caso não seja feito, ocorrerão erros nas exportações de seus produtos, e eu não serei capaz de buscar automaticamente a PLP de seus pedidos!`,
            links: [
                {
                    name: "Config. 1",
                    url: "https://youtu.be/iDxZ9tpoNjA",
                },
                {
                    name: "Config. 2",
                    url: "https://youtu.be/XzLxiQDNU14",
                },
                {
                    name: "Config. 3",
                    url: "https://youtu.be/tXuDGVtWAL8",
                },
            ],
            additional: `Após isso, vá até o menu "Integrações" na Orizom, e complete a seguinte etapa:`,
            conditions: [
                {
                    name: "Adicione uma integração com o Bling",
                    fulfilled:
                        integrations && Object.values(integrations).length,
                },
                // {
                //     name: "Adicione pelo menos um marketplace em sua integração",
                //     fulfilled: integrations && Object.values(integrations)?.find((e) => e.type == 0)?.config2?.length,
                // },
            ],
            button: "Já configurei",
            bonus: 10,
        },
        {
            name: "Bling",
            description: `Eu criei todas as categorias e campos customizados para você, mas ainda é necessário você fazer algumas configurações em seu Bling. Você deve mapear os campos customizados das categorias criadas conforme o vídeo explicativo.
E também, para ser capaz de exportar os produtos para o marketplace, você deve fazer a conexão do produto com a categoria do marketplace desejado.
Essa configuração é essencial para venda no Mercado Livre.`,
            links: [
                {
                    name: "Tutorial Categorias",
                    url: "https://www.youtube.com/watch?v=8yNsQNJXpnQ",
                },
                {
                    name: "Exportação de Produtos",
                    url: "https://youtu.be/jn47fpvtm5s",
                },
            ],
            button: "Já fiz as configurações",
        },
        {
            name: "10 vendas",
            description:
                "Esse é o verdadeiro início da sua jornada! Realize suas 10 primeiras vendas na Orizom para desbloquear novas etapas remuneradas!",
            conditions: [
                {
                    name: `Realize mais ${Math.max(10 - numOrders, 0)} venda${
                        10 - numOrders !== 1 ? "s" : ""
                    }!`,
                    fulfilled: numOrders >= 10,
                },
            ],
            button: "Pronto! Avançar!",
            bonus: 100,
        },
        {
            name: "100 vendas",
            description: `Parabéns! Você já está no caminho de ser um franqueado de sucesso!

Agora, complete suas 100 primeiras vendas para continuar sua jornada vendendo junto com a Orizom!`,
            conditions: [
                {
                    name: `Realize mais ${Math.max(100 - numOrders, 0)} venda${
                        100 - numOrders !== 1 ? "s" : ""
                    }!`,
                    fulfilled: numOrders >= 100,
                },
            ],
            button: "Fiz 100 vendas!",
            bonus: 500,
        },
        {
            name: "1000 vendas",
            description: `Parabéns pelas 100 vendas! Com certeza é um número alto, mas agora seu o desafio é ainda maior: alcançar suas primeiras 1000 vendas!
Continue no mesmo ritmo, que de acordo com seu histórico, sei que você consegue!`,
            conditions: [
                {
                    name: `Realize mais ${Math.max(1000 - numOrders, 0)} venda${
                        1000 - numOrders !== 1 ? "s" : ""
                    }!`,
                    fulfilled: numOrders >= 1000,
                },
            ],
            button: "Fiz 1000 vendas!",
            bonus: 2500,
        },
        {
            name: "5000 vendas",
            description: `Parabéns! São poucos franqueados que conseguem chegar nesse nível.

Celebre muito esse momento, mas os desafios não param por aí.
Próxima parada: 5.000 vendas!`,
            conditions: [
                {
                    name: `Realize mais ${Math.max(5000 - numOrders, 0)} venda${
                        5000 - numOrders !== 1 ? "s" : ""
                    }!`,
                    fulfilled: numOrders >= 5000,
                },
            ],
            button: "Fiz 5000 vendas!",
            bonus: 5000,
        },
        {
            name: "10000 vendas",
        },
    ];

    const [angleBetween, setAngleBetween] = useState(6);
    const [selectedStage, setSelectedStage] = useState(user?.stage || 0);
    const [showBtn, setShowBtn] = useState(false);
    const [showAdditional, setShowAdditional] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const [firstStepsModal, setFirstStepsModal] = useState(false);

    const [maxStage, setMaxStage] = useState(user?.stage || 0);

    const [fullImage, setFullImage] = useState(null);

    useEffect(() => {
        setAngleBetween(firstStepsModal ? 10 : 6);
    }, [firstStepsModal]);

    return (
        <>
            <div
                className={`image-modal-bg ${fullImage ? "shown" : ""}`}
                onClick={() => setFullImage(null)}
            >
                <div
                    className="image-modal"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <img
                        src={
                            fullImage?.replace("thumbs", "products") +
                            `?v=${Date.now()}`
                        }
                        alt="imagem"
                    />
                </div>
            </div>
            <div
                className={`first-steps-modal-bg ${
                    firstStepsModal ? "shown" : ""
                }`}
                onClick={() => {
                    setFirstStepsModal(false);
                    setShowBtn(false);
                    setShowLinks(false);
                    setShowAdditional(false);
                }}
            >
                <div
                    className="first-steps-modal"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div className="first-steps-title">Plano de voo</div>
                    {firstStepsModal ? (
                        <div
                            className="first-steps-message"
                            key={selectedStage}
                        >
                            <Einstein
                                type="say"
                                className="first-steps-einstein"
                            />
                            <div className="first-steps-text">
                                <TypeAnimation
                                    key={selectedStage}
                                    sequence={[
                                        () => {
                                            setShowBtn(false);
                                            setShowLinks(false);
                                            setShowAdditional(false);
                                        },
                                        1500,
                                        stages[selectedStage].description,
                                        400,
                                        () => {
                                            setShowLinks(true);
                                            if (
                                                stages[selectedStage].additional
                                            ) {
                                                setShowAdditional(
                                                    () => selectedStage
                                                );
                                            } else {
                                                setShowBtn(true);
                                            }
                                        },
                                    ]}
                                    speed={{
                                        type: "keyStrokeDelayInMs",
                                        value: 1,
                                    }}
                                />
                                <div className={`first-steps-links`}>
                                    {stages[selectedStage].links &&
                                        stages[selectedStage].links.map(
                                            (link, i) => {
                                                if (link.copy) {
                                                    return (
                                                        <CopyDiv
                                                            key={i}
                                                            value={link.copy}
                                                            className={`first-steps-modal-btn ${
                                                                showLinks
                                                                    ? "shown"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {link.name}
                                                        </CopyDiv>
                                                    );
                                                } else {
                                                    return (
                                                        <a
                                                            key={i}
                                                            href={link.url}
                                                            target="_blank"
                                                            className={`first-steps-modal-btn ${
                                                                showLinks
                                                                    ? "shown"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {link.name}
                                                        </a>
                                                    );
                                                }
                                            }
                                        )}
                                </div>
                                {stages[selectedStage].additional &&
                                showAdditional ? (
                                    <TypeAnimation
                                        key={`${selectedStage}adittional`}
                                        sequence={[
                                            600,
                                            stages[selectedStage].additional,
                                            400,
                                            () => setShowBtn(true),
                                        ]}
                                        speed={{
                                            type: "keyStrokeDelayInMs",
                                            value: 1,
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                {stages[selectedStage].conditions &&
                                    showBtn &&
                                    stages[selectedStage].conditions.map(
                                        (cond, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={`first-steps-condition ${
                                                        cond.fulfilled
                                                            ? "fulfilled"
                                                            : ""
                                                    }`}
                                                >
                                                    <div className="first-steps-fulfilled" />
                                                    {cond.name}
                                                </div>
                                            );
                                        }
                                    )}
                                <div className="first-steps-buttons">
                                    <div
                                        className={`first-steps-modal-btn ${
                                            showBtn ? "shown" : ""
                                        } ${
                                            stages[selectedStage].conditions &&
                                            stages[
                                                selectedStage
                                            ].conditions.some(
                                                (cond) => !cond.fulfilled
                                            )
                                                ? "disabled"
                                                : ""
                                        }`}
                                        onClick={async () => {
                                            setSelectedStage(selectedStage + 1);
                                            setMaxStage(selectedStage + 1);
                                            await fetch(
                                                "/api/gpt/changeuserstage",
                                                {
                                                    method: "POST",
                                                    body: JSON.stringify({
                                                        stage:
                                                            selectedStage + 1,
                                                    }),
                                                    headers: {
                                                        "Content-Type":
                                                            "application/json",
                                                    },
                                                }
                                            );
                                        }}
                                    >
                                        {stages[selectedStage].button}
                                    </div>
                                    {stages[selectedStage].bonus &&
                                    selectedStage === maxStage &&
                                    showBtn ? (
                                        <div className="first-steps-bonus">
                                            Complete & Ganhe{" "}
                                            <strong>
                                                <small>R$</small>
                                                {stages[selectedStage].bonus}
                                            </strong>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        className="spacex-circle"
                        style={{
                            transform: `translateX(-50%) rotateZ(${
                                -selectedStage * angleBetween
                            }deg)`,
                        }}
                    >
                        {stages.map((e, i) => {
                            return (
                                <div
                                    key={i}
                                    onClick={() => setSelectedStage(i)}
                                    className={`dot ${
                                        selectedStage === i ? "selected" : ""
                                    }
                                    ${i > maxStage ? "disabled" : ""}`}
                                    style={{
                                        top:
                                            radius -
                                            getCirclePos(i * angleBetween).y,
                                        left:
                                            radius +
                                            getCirclePos(i * angleBetween).x,
                                        transform: `translateX(-50%) translateY(-50%) rotateZ(${
                                            i * angleBetween
                                        }deg)`,
                                    }}
                                >
                                    <div
                                        className="dot-name"
                                        style={{
                                            top: `${
                                                i % 2 == 0 ? "2px" : "auto"
                                            }`,
                                            bottom: `${
                                                i % 2 == 0 ? "auto" : "2px"
                                            }`,
                                        }}
                                    >
                                        {e.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="actual-stage">
                        {stages[selectedStage].name}
                    </div>
                    <Einstein type="say" className="step-mini-einstein" />
                </div>
            </div>
            <div
                className={`aistem-mini-bg ${
                    lastMessage || aistemOpen ? "shown" : ""
                }`}
                onClick={() => {
                    if (user.screening) setAistemOpen(false);
                    setLastMessage(null);
                    audio.pause();
                    audio.currentTime = 0;
                    setSpeaking(false);
                    setSpeakingAudio(false);
                }}
            />
            <div
                className={`aistem-mini ${
                    listening || listeningManual ? "listening" : ""
                } ${lastMessage ? "transformed" : ""} ${
                    speaking ? "speaking" : ""
                } ${aistemOpen ? "transformed2" : ""}
                ${hidden ? "hidden" : ""}`}
                onClick={() => {
                    setAistemOpen(true);
                }}
            >
                <div className="aistem-mini-row">
                    <img
                        src={`/AIstem/aistem_start2.gif?v=${logoStage}`}
                        className={`aistem-gif ${
                            logoStage >= 0 && speaking ? "shown" : ""
                        } shownfirst`}
                    />
                    <img
                        src={`/AIstem/aistem.gif`}
                        className={`aistem-gif ${
                            logoStage >= 1 && speaking ? "shown" : ""
                        }`}
                    />
                    <img
                        src={`/AIstem/aistem_end.gif?v=${logoStage}`}
                        className={`aistem-gif ${
                            logoStage >= 2 && speaking ? "shown" : ""
                        }`}
                    />

                    <Einstein className={`aistem-mini-logo`} start={!waiting} />

                    <div className="aistem-mini-text">
                        {listening || listeningManual ? (
                            <TypeAnimation
                                key={1}
                                sequence={["Estou te ouvindo!"]}
                                speed={{
                                    type: "keyStrokeDelayInMs",
                                    value: 25,
                                }}
                            />
                        ) : waiting ? (
                            <TypeAnimation
                                key={2}
                                sequence={["Estou pensando..."]}
                                speed={{
                                    type: "keyStrokeDelayInMs",
                                    value: 25,
                                }}
                            />
                        ) : (
                            <TypeAnimation
                                key={3}
                                sequence={[
                                    `${
                                        new Date().getHours() < 12
                                            ? "Bom dia"
                                            : new Date().getHours() < 18
                                            ? "Boa tarde"
                                            : "Boa noite"
                                    }, ${user.name.split(" ")[0]}!`,
                                ]}
                                speed={{
                                    type: "keyStrokeDelayInMs",
                                    value: 25,
                                }}
                            />
                        )}
                    </div>

                    {user.screening ? (
                        <div
                            className={`aistem-mic-btn ${mute ? "muted" : ""} ${
                                (listening || listeningManual) && transcript
                                    ? "speaking"
                                    : ""
                            }`}
                            {...minLongPressEvent}
                            onClick={(ev) => {
                                ev.stopPropagation();
                            }}
                        >
                            {(listening || listeningManual) && transcript ? (
                                <div className="aistem-user-speaking">
                                    <span className="blue" />
                                    <span className="red" />
                                    <span className="yellow" />
                                    <span className="green" />
                                </div>
                            ) : (
                                <img src="/img/mic.svg" />
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                {lastMessage && !aistemOpen ? (
                    <div className="aistem-speak">
                        <div className="aistem-dialog-text">
                            {/* {transcript} */}
                            {true ? (
                                <TypeAnimation
                                    key={content}
                                    sequence={[
                                        400,
                                        content,
                                        100,
                                        () => {
                                            setShowButtons(true);
                                            // if (props.message.onLoad) {
                                            //     props.message.onLoad();
                                            // }
                                            // if (props.message.resolve) props.message.resolve();
                                        },
                                    ]}
                                    speed={{
                                        type: "keyStrokeDelayInMs",
                                        value: 1,
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            {lastMessage?.buttons ? (
                                <div className={`einstein-dialog-buttons`}>
                                    {lastMessage?.buttons?.map((button, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={`einstein-button ${
                                                    showButtons ? "shown" : ""
                                                }

                                    `}
                                                onClick={button.action}
                                            >
                                                {button.text}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <></>
                            )}
                            {!images &&
                            lastMessage?.function &&
                            lastMessage?.function?.produtos_buscados?.some(
                                (e) => e.photo_url
                            ) ? (
                                <div
                                    className={`message-product-photos ${
                                        showButtons ? "shown" : ""
                                    }`}
                                >
                                    {lastMessage?.function?.produtos_buscados.map(
                                        (p, i) => {
                                            if (!p?.photo_url) return;
                                            return (
                                                <div
                                                    className="message-product-photo"
                                                    key={i}
                                                    style={{
                                                        transitionDelay: `${
                                                            200 * (i + 1)
                                                        }ms`,
                                                    }}
                                                >
                                                    <div className="message-product-sku">
                                                        {p.sku}
                                                    </div>
                                                    <img
                                                        src={`${p.photo_url}`}
                                                    />
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            ) : (
                                <></>
                            )}
                            {links ? (
                                <div className={`einstein-dialog-links`}>
                                    {[...(links || [])].map((button, i) => {
                                        if (button.copy) {
                                            return (
                                                <CopyDiv
                                                    key={i}
                                                    value={button.copy}
                                                    className={`einstein-button ${
                                                        showButtons
                                                            ? "shown"
                                                            : ""
                                                    } clickable`}
                                                >
                                                    {button.text}
                                                </CopyDiv>
                                            );
                                        } else {
                                            return (
                                                <a
                                                    key={i}
                                                    href={button.url}
                                                    target="_blank"
                                                    className={`einstein-button ${
                                                        showButtons
                                                            ? "shown"
                                                            : ""
                                                    } clickable`}
                                                >
                                                    {button.text}
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                            ) : (
                                <></>
                            )}

                            {images ? (
                                <div
                                    className={`einstein-dialog-images ${
                                        showButtons ? "shown" : ""
                                    }`}
                                >
                                    {images.map((button, i) => {
                                        return (
                                            <div className="einstein-image">
                                                <div className="einstein-image-overlay">
                                                    Expandir
                                                </div>
                                                <img
                                                    key={i}
                                                    src={button.url}
                                                    alt={button.text}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                <AIstemChat
                    setFirstStepsModal={setFirstStepsModal}
                    setFullImage={setFullImage}
                    setLastMessage={setLastMessage}
                    setWaiting={setWaiting}
                    audio={audio}
                    setReply={setReply}
                    reply={reply}
                    setSpeaking={setSpeakingAudio}
                    setAistemOpen={setAistemOpen}
                    resetTranscript={resetTranscript}
                />
            </div>
        </>
    );
};

export default VoiceWidget;
